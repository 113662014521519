import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { SearchResults } from "./SearchResults";
import Axios, { AxiosRequestConfig, Method } from "axios";
import IndicatorValidate from "../../helpers/IndicatorValidate";

import { useDispatch, useSelector } from "react-redux";
import {
  searchSelector,
  SearchState,
  setSearchError,
  setSearchResults,
  setSearchTerms,
  setSearchText,
} from "../../../../slices/search";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  ButtonGroup,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  DeleteSearchJSON,
  isExternal,
  RecentSearchesJSON,
  RecentSearchJSON,
  SearchMoreJSON,
  SearchTermsJSON,
} from "../../helpers/ServerProperties";
import { searchExamples } from "./searchExamples";
import { SearchResultsResponse } from "../../models/search/SearchResult";
import { SearchSaveButtons } from "./SearchSaveButtons";
import _ from "lodash";

import { isSimplified } from "components/beaver/helpers/ServerProperties";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBox: {
      margin: "2em",
    },

    button: {
      marginLeft: "0.5em",
    },
  })
);

export function SearchPage() {
  const location = useLocation();
  const classes = useStyles();
  const [errorText, setErrorText]: [string, React.Dispatch<string>] =
    useState(null);
  const [searchText, setSearchText]: [string, React.Dispatch<string>] =
    useState("");
  const [data, setData]: [
    SearchResultsResponse,
    React.Dispatch<SearchResultsResponse>
  ] = useState(null);
  const [searching, setSearching]: [boolean, React.Dispatch<boolean>] =
    useState();
  const [recentSearches, setRecentSearches] = useState([]);
  const [recentSearch, setRecentSearchValue] = useState(0);
  const [terms, setTerms] = useState();
  const [reloadRecents, setReloadRecents] = useState(0);
  const [deleteError, setDeleteError] = useState(false);
  const [searchTextHistory, setSearchTextHistory] = useState([]);
  const [searchTextHistoryIndex, setSearchTextHistoryIndex] = useState(0);
  const dispatch = useDispatch();

  const searchState: SearchState = useSelector(searchSelector);

  const handleError = (error: any) => {
    // if it's a 401, logout because bad creds, the user can then log back in
    if (error.response && error.response.status === 401) {
      setTimeout(() => {
        setErrorText(t("You may need to logout and login again"));
      }, 1200);
      setTimeout(() => {
        if (localStorage.ssoPrefix) localStorage.removeItem("ssoPrefix");
        if (localStorage.currentUser) localStorage.removeItem("currentUser");
        window.location.reload();
      }, 3000);
    }
  };

  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};

  const setRecentSearch = (searchId: number) => {
    setRecentSearchValue(searchId);
    // if zero, then ignore it
    if (!searchId) return;

    setSearching(true);

    // do axios search
    Axios.get(RecentSearchJSON(searchId), {
      auth:
        user.server && !user.server.includes("localhost")
          ? {
              username: user ? user.username : null,
              password: user
                ? user.onetime
                  ? // eslint-disable-next-line no-eval
                    window.atob(eval(window.atob(user.onetime))).substr(13)
                  : null
                : null,
            }
          : null,
    }).then(
      (response) => {
        // TODO - remove this
        console.log(response);
        setData(response.data);
        setSearching(false);
      },
      (error) => {
        console.error(error);
        setErrorText(error.message);
        setSearching(false);
      }
    );
  };

  // what this will do is take the query param, then put it in the search box and search for it!
  // you can use comma, semi-colon or spaces beteween stuff
  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get("q");
    if (query && query.trim() !== "") {
      const fixedQuery = query
        // replace any spaces inside of square brackets with `+++` (could've been anything really)
        .replace(/\s+(?=[^[\]]*\])/g, "+++")
        .replace(/[,;\s]/g, "\n")
        // if there are multiple spaces before the word, e.g. tag[ something ], the spaces which are now +'s get removed after and before the square brackets
        .replace(/\[\++/, "[")
        .replace(/\++\]/, "]")
        // here we make the `+++` back to spaces
        .replaceAll("+++", " ")
        // remove any extra \n's / empty lines just in case the user has e.g. extra comma or space between terms
        .replace(/(\n)\1+/g, "\n");
      setSearchText(fixedQuery);
      doSearch(
        setSearching,
        fixedQuery,
        handleError,
        setErrorText,
        setData,
        setTerms,
        dispatch
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  React.useEffect(() => {
    // console.log(searchState);
    if (searchState) {
      if (searchState.searchText) setSearchText(searchState.searchText);
      if (searchState.searchResults) setData(searchState.searchResults);
      if (searchState.searchTextHistory.length)
        setSearchTextHistory(searchState.searchTextHistory);
    }
  }, [searchState]);

  React.useEffect(() => {
    
    // Recent search is not supported on the external instance.
    // Avoid doing the call if we know it'll fail.
    if (isExternal())
      return;

    Axios.get(RecentSearchesJSON(), {
      auth:
        user.server && !user.server.includes("localhost")
          ? {
              username: user ? user.username : null,
              password: user
                ? user.onetime
                  ? // eslint-disable-next-line no-eval
                    window.atob(eval(window.atob(user.onetime))).substr(13)
                  : null
                : null,
            }
          : null,
    }).then((res) => {
      if (Array.isArray(res.data)) setRecentSearches(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadRecents]);

  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>BEAVER {t("page.search.title")}</title>
        <meta
          name="description"
          content={[t("drawer.search"), "-", t("page.home.description")].join(
            " "
          )}
        />
      </Helmet>
      {!!recentSearches.length && !isSimplified() && (
        <Box
          className={classes.searchBox}
          mt={2}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Box style={{ display: "flex" }}>
            <Typography variant="h6">{t("Recent Search")}:</Typography>
          </Box>

          <Select
            value={recentSearch}
            onChange={(event) =>
              setRecentSearch(parseInt("" + event.target.value))
            }
          >
            <MenuItem value={0}>{t("Select a Recent Search")}:</MenuItem>
            {recentSearches.map((r, i) => (
              <MenuItem key={i} value={r.searchId}>
                {r.searchId + " - " + r.comment}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}

      {!!searchTextHistory.length && !isSimplified() && (
        <Box
          className={classes.searchBox}
          mt={2}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Box style={{ display: "flex" }}>
            <Typography variant="h6">{t("Search History")}:</Typography>
          </Box>
          <Select
            value={searchTextHistoryIndex}
            onChange={(event) => {
              // set the search text
              setSearchTextHistoryIndex(parseInt("" + event.target.value));
              doSearch(
                setSearching,
                searchTextHistory[parseInt("" + event.target.value) - 1],
                handleError,
                setErrorText,
                setData,
                setTerms,
                dispatch
              );
            }}
          >
            <MenuItem value={0}>{t("Select from Search History")}:</MenuItem>
            {searchTextHistory.map((r, i) => (
              <MenuItem key={i} value={i + 1}>
                {r.length > 60 ? r.substring(0, 60) + " ..." : r}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}

      <div className={classes.searchBox}>
        {!recentSearch ? (
          <>
            <Box style={{ display: "flex" }}>
              <Typography variant="h6">{t("New Search")}:</Typography>
            </Box>
            <p>
              {isSimplified()
                ? t("beaver.search.supportedtypes.simplified")
                : t("beaver.search.supportedtypes")}
            </p>
            <Paper>
              <TextField
                error={Boolean(errorText)}
                helperText={errorText ? "Error: " + errorText : null}
                id="outlined-multiline-static"
                label=""
                multiline
                rows={12}
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                variant="outlined"
                fullWidth
              />
            </Paper>
            {errorText && !errorText.match(/malformed/) ? (
              <Box mt={2}>
                <img
                  src="/ng/images/beaver_error_logo.png"
                  alt="SAD BEAVER"
                  style={{ maxHeight: "25vh", mixBlendMode: "darken" }}
                />
                <Typography>
                  {t("An Error Occured!")}: {errorText}
                </Typography>
              </Box>
            ) : null}
            <br />
            <div>
              <SearchButtons
                doSearch={() =>
                  doSearch(
                    setSearching,
                    searchText,
                    handleError,
                    setErrorText,
                    setData,
                    setTerms,
                    dispatch
                  )
                }
                loadExamplesText={() => {
                  setSearchText(
                    isSimplified()
                      ? searchExamples.searchtextsimple
                      : searchExamples.searchtext
                  );
                  doSearch(
                    setSearching,
                    isSimplified()
                      ? searchExamples.searchtextsimple
                      : searchExamples.searchtext,
                    handleError,
                    setErrorText,
                    setData,
                    setTerms,
                    dispatch
                  );
                }}
                searching={searching}
                clearBox={() => {
                  setSearchText("");
                  setErrorText(null);
                }}
                data={data}
              />
            </div>
          </>
        ) : (
          <Box mt={2} onClick={() => setRecentSearch(0)}>
            <Button variant="outlined">{t("New Search")}</Button>
          </Box>
        )}
        <br />
        <div id="searchresults">
          <SearchResults responsedata={data} searching={searching} />
          {terms && !searching && !recentSearch && !isSimplified() && (
            <SearchSaveButtons
              terms={terms}
              recentSearches={recentSearches}
              setRecentSearches={setRecentSearches}
              setRecentSearchValue={setRecentSearchValue}
            />
          )}
          {!!recentSearch && (
            <Box
              mt={1}
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  // it's a get in beaver !  don't judge
                  Axios.get(DeleteSearchJSON(recentSearch), {
                    auth:
                      user.server && !user.server.includes("localhost")
                        ? {
                            username: user ? user.username : null,
                            password: user
                              ? user.onetime
                                ? window
                                    // eslint-disable-next-line no-eval
                                    .atob(eval(window.atob(user.onetime)))
                                    .substr(13)
                                : null
                              : null,
                          }
                        : null,
                  }).then(
                    (res) => {
                      //refresh
                      setReloadRecents(reloadRecents + 1);
                      setRecentSearch(0);
                      setData(null);
                    },
                    (err) => {
                      console.log(err);
                      setDeleteError(true);
                      setTimeout(() => setDeleteError(false), 3000);
                    }
                  );
                }}
              >
                {t("Delete this search")}
              </Button>
            </Box>
          )}
          {deleteError && (
            <span style={{ color: "red" }}>{t("Could not Delete")}</span>
          )}
        </div>
      </div>
    </>
  );
}

export type SearchButtonsProps = {
  searching: boolean;
  clearBox: () => void;
  loadExamplesText: () => void;
  doSearch: () => void;
  data: SearchResultsResponse;
};

export function SearchButtons({
  searching,
  clearBox,
  loadExamplesText,
  doSearch,
  data,
}: SearchButtonsProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!searching)
    return (
      <div>
        <ButtonGroup
          color="primary"
          aria-label="outlined primary button group"
          style={{ paddingTop: "5px" }}
        >
          <Button className={classes.button} onClick={doSearch}>
            {t("beaver.search.search")}
          </Button>
          <Button className={classes.button} onClick={loadExamplesText}>
            {t("beaver.search.show_examples")}
          </Button>
          {data ? (
            <Button
              className={classes.button}
              onClick={() => {
                if (data.result && data.result.length) {
                  data.result.forEach((d) => {
                    let artifacts: number = parseInt(d.searchResult);
                    if (
                      !isNaN(artifacts) &&
                      artifacts &&
                      "asn" !== d.typeTitle
                    ) {
                      let url: string = [
                        window.location.protocol + "//",
                        window.location.host,
                        "/ng",
                        d.reportURL,
                      ].join("");
                      // NOTE:  if user complains that the tabs aren't loading, then let them know to enable the domain in popup blocker in e.g. chrome
                      window.open(url, "_blank");
                    }
                  });
                }
              }}
            >
              {t("Open all in new tabs")}
            </Button>
          ) : null}
          <Button className={classes.button} onClick={clearBox}>
            {t("beaver.search.clear")}
          </Button>
        </ButtonGroup>
      </div>
    );
  else return <span>{t("beaver.search.searching")}</span>;
}

// make the validtor any otherwise it won't compile
const validator: any = new IndicatorValidate(true, isSimplified());

const doSearch = (
  setSearching: React.Dispatch<boolean>,
  searchText: string,
  handleError: React.Dispatch<any>,
  setErrorText: React.Dispatch<string>,
  setData: React.Dispatch<SearchResultsResponse>,
  setTerms,
  dispatch
) => {
  setSearching(true);

  // parse the inputs
  let valid = validator.validate(searchText);

  // TODO - should i keep going if there's an error or just return? for now, will just return
  if (!valid) {
    // bind this somewhere
    setErrorText(validator.getErrorText);
    setSearching(false);
    return;
  } else {
    setErrorText(null);
  }

  var terms = validator.getTermsAfterValidation();

  var payload = [];

  for (var term in terms) {
    payload.push({ type: terms[term], value: term });
  }

  setTerms(payload);

  // do the post
  let searchTerms = { msg: payload };

  dispatch(setSearchText(searchText));
  dispatch(setSearchTerms(searchTerms));

  //TODO - this should make the localstorage null if it can't parse- put this somewhere and export
  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};

  Axios({
    method: SearchTermsJSON().method as Method,
    url: SearchTermsJSON().url,
    data: searchTerms,
    auth:
      user.server && !user.server.includes("localhost")
        ? {
            username: user ? user.username : null,
            password: user
              ? user.onetime
                ? // eslint-disable-next-line no-eval
                  window.atob(eval(window.atob(user.onetime))).substr(13)
                : null
              : null,
          }
        : null,
  } as AxiosRequestConfig).then(
    (response) => {
      // TODO - remove this
      console.log(response);
      setData(response.data);
      dispatch(setSearchResults(response.data));
      setSearching(false);
      // if there is still more, then send that to the doMore
      const moreData = response.data.result
        .filter((d) => !d.searchResult)
        .map((d) => d.searchTerm)
        .map((d) => ({ type: d.type.toLowerCase(), value: d.value }));
      if (moreData?.length)
        doMore(
          moreData,
          response.data,
          setData,
          handleError,
          setErrorText,
          dispatch
        );
    },
    (error) => {
      console.error(error);
      handleError(error);
      setErrorText(error.message);
      dispatch(setSearchError(error.message));
      setSearching(false);
    }
  );
};

const doMore = (
  payload,
  prevData,
  setData,
  handleError,
  setErrorText,
  dispatch
) => {
  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};

  Axios({
    method: SearchMoreJSON().method as Method,
    url: SearchMoreJSON().url,
    data: { msg: payload },
    auth:
      user.server && !user.server.includes("localhost")
        ? {
            username: user ? user.username : null,
            password: user
              ? user.onetime
                ? // eslint-disable-next-line no-eval
                  window.atob(eval(window.atob(user.onetime))).substr(13)
                : null
              : null,
          }
        : null,
  } as AxiosRequestConfig).then(
    (response) => {
      // TODO - remove this
      console.log(response);
      // here, you have to append the results to the existing search data
      // mix the prevData with the response data - basically take the prevData, remove the null searchResults
      // then append this data there.
      // debugger;
      let data = _.cloneDeep(prevData);
      data.result = _.concat(
        prevData.result.filter((d) => d.searchResult),
        response.data.result
      );

      setData(data);
      dispatch(setSearchResults(data));

      // if there is still more, then send that to the doMore again (recursion)
      const moreData = response.data.result
        .filter((d) => !d.searchResult)
        .map((d) => d.searchTerm)
        .map((d) => ({ type: d.type.toLowerCase(), value: d.value }));
      if (moreData?.length)
        doMore(moreData, data, setData, handleError, setErrorText, dispatch);
    },
    (error) => {
      console.error(error);
      handleError(error);
      setErrorText(error.message);
      dispatch(setSearchError(error.message));
    }
  );
};
