import { useCallback, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPreview, previewSelector } from "slices/preview";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../common/sharedStyles";
import Close from "@material-ui/icons/Close";
import {
  Button,
  Box,
  Modal,
  Paper,
  Typography,
  Switch,
  Select,
  MenuItem,
  LinearProgress,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";

export function SamplePreviewModal({
  md5,
  canPreview,
  showModal,
  setShowModal,
}: {
  md5: string;
  canPreview: boolean;
  showModal: boolean;
  setShowModal: Function;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <Paper className={classes.previewModal}>
        <Box className={classes.previewModalLayout}>
          <Box
            style={{ borderBottom: "2px solid rgba(224, 224, 224, 1)" }}
            mb={2}
          >
            <Box
              style={{
                float: "right",
                cursor: "pointer",
              }}
              onClick={() => setShowModal(false)}
            >
              <Close />
            </Box>
            <Typography variant="h5">{t("Preview sample")}</Typography>
          </Box>
          <SamplePreview md5={md5} canPreview={canPreview} />
        </Box>
      </Paper>
    </Modal>
  );
}

export function SamplePreview({
  md5,
  canPreview,
  vizData,
}: {
  md5: string;
  canPreview: boolean;
  vizData?: string;
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const { previewData, error } = useSelector(previewSelector);

  const preview = previewData?.preview;
  const hexDump = previewData?.hexDump;
  const lineCount = preview?.length;

  useEffect(() => {
    dispatch(fetchPreview(md5, false));
  }, [dispatch, md5]);

  const [renderLines, setRenderLines] = useState(1000);
  const [viewFullPreview, setViewFullPreview] = useState(
    Boolean(!vizData || preview)
  );
  const [viewHex, setViewHex] = useState(
    "viewHex" in localStorage
      ? JSON.parse(localStorage.getItem("viewHex"))
      : false
  );
  const [hexLayout, setHexLayout] = useState(
    "hexLayout" in localStorage ? localStorage.getItem("hexLayout") : "side"
  );
  const [wordWrap, setWordWrap] = useState(
    "previewWordWrap" in localStorage
      ? JSON.parse(localStorage.getItem("previewWordWrap"))
      : true
  );

  const toggleHex = useCallback(() => {
    localStorage.setItem("viewHex", JSON.stringify(!viewHex));
    setViewHex(!viewHex);
  }, [viewHex]);

  const changeHexLayout = useCallback((layout) => {
    localStorage.setItem("hexLayout", layout);
    setHexLayout(layout);
  }, []);

  const toggleWordWrap = useCallback(() => {
    localStorage.setItem("previewWordWrap", JSON.stringify(!wordWrap));
    setWordWrap(!wordWrap);
  }, [wordWrap]);

  const previewElem = useRef();
  const hexDumpElem = useRef();

  function renderMoreLines(elem) {
    if (
      Math.abs(elem.scrollTop + elem.offsetHeight - elem.scrollHeight) < 5 &&
      lineCount > renderLines
    ) {
      setRenderLines(renderLines + 1000);
    }
  }

  function syncScroll(source: HTMLElement, target: HTMLElement) {
    if (!target) return;

    target.scrollTo({
      top:
        (target.scrollHeight - target.offsetHeight) *
        (source.scrollTop / (source.scrollHeight - source.offsetHeight)),
      left:
        (target.scrollWidth - target.offsetWidth) *
        (source.scrollLeft / (source.scrollWidth - source.offsetWidth)),
      behavior: "instant",
    });
  }

  if (canPreview && !preview && !hexDump && !error && !vizData) {
    return <LinearProgress />;
  } else if ((canPreview && !error) || vizData) {
    return (
      <>
        <Box
          style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          mb={1}
        >
          <Box className={classes.previewSetting}>
            <Typography
              variant="subtitle1"
              style={{ opacity: viewFullPreview ? "1" : "0.5" }}
            >
              {t("Show hex")}
            </Typography>
            <Switch
              disabled={!viewFullPreview}
              checked={viewHex}
              size="small"
              onChange={toggleHex}
              name="textToggle"
            />
            <div
              className={classes.vl}
              style={{
                marginInline: "5px",
                opacity: viewFullPreview ? "1" : "0.5",
              }}
            ></div>
            <Select
              disabled={!viewHex || !viewFullPreview}
              value={hexLayout}
              onChange={(event) =>
                changeHexLayout(event.target.value as string)
              }
            >
              <MenuItem value={"side"}>{t("Side by side")}</MenuItem>
              <MenuItem value={"fullscreen"}>{t("Fullscreen")}</MenuItem>
            </Select>
          </Box>
          <Box className={classes.previewSetting}>
            <Typography
              variant="subtitle1"
              style={{ opacity: viewFullPreview ? "1" : "0.5" }}
            >
              {t("Word wrap")}
            </Typography>
            <Switch
              disabled={!viewFullPreview}
              checked={wordWrap}
              size="small"
              onChange={toggleWordWrap}
              name="textToggle"
            />
          </Box>
          {vizData && (
            <Tooltip title={error ? t(error) : ""}>
              <Box className={classes.previewSetting}>
                <Typography
                  variant="subtitle1"
                  style={{ opacity: canPreview ? "1" : "0.5" }}
                >
                  {t("Full preview")}
                </Typography>
                {canPreview && !error && !preview ? (
                  <CircularProgress style={{ width: "20px", height: "20px" }} />
                ) : (
                  <Switch
                    disabled={!canPreview || Boolean(error)}
                    checked={viewFullPreview}
                    size="small"
                    onChange={() => setViewFullPreview(!viewFullPreview)}
                    name="textToggle"
                  />
                )}
              </Box>
            </Tooltip>
          )}
        </Box>

        {viewFullPreview ? (
          <>
            <div
              style={{
                borderRadius: "5px",
                height: "100%",
                overflow: "hidden",
                display: "flex",
              }}
            >
              <pre
                className={classes.preview}
                style={{
                  overflow: (wordWrap ? "hidden" : "auto") + " auto",
                  width: hexLayout === "side" || !viewHex ? "100%" : "0%",
                }}
                ref={previewElem}
                onScroll={(e) => {
                  renderMoreLines(e.target as HTMLElement);
                  syncScroll(e.target as HTMLElement, hexDumpElem?.current);
                }}
              >
                <ol
                  className={classes.ol}
                  style={{
                    paddingLeft: 40 + lineCount?.toString().length * 7 + "px",
                  }}
                >
                  {preview
                    ?.slice(0, Math.min(preview?.length, renderLines))
                    .map((line: string, id: number) => (
                      <li
                        className={classes.li}
                        style={{
                          whiteSpace: wordWrap ? "break-spaces" : "pre",
                        }}
                        key={"preview_" + id}
                      >
                        {line}
                      </li>
                    ))}
                </ol>
              </pre>

              {viewHex && hexLayout === "side" && (
                <div className={classes.vl}></div>
              )}

              {viewHex && (
                <pre
                  className={classes.preview}
                  style={{
                    overflow: (wordWrap ? "hidden" : "auto") + " auto",
                    width: "100%",
                  }}
                  ref={hexDumpElem}
                  onScroll={(e) => {
                    renderMoreLines(e.target as HTMLElement);
                    syncScroll(e.target as HTMLElement, previewElem?.current);
                  }}
                >
                  <ol
                    className={classes.ol}
                    style={{
                      paddingLeft: 40 + lineCount?.toString().length * 7 + "px",
                    }}
                  >
                    {hexDump
                      ?.slice(0, Math.min(hexDump?.length, renderLines))
                      .map((line: string, id: number) => (
                        <li
                          className={classes.li}
                          style={{
                            whiteSpace: wordWrap ? "break-spaces" : "pre",
                          }}
                          key={"hex_" + id}
                        >
                          {line}
                        </li>
                      ))}
                  </ol>
                </pre>
              )}
            </div>
            {lineCount > renderLines && vizData && (
              <Button
                className={classes.nm}
                color="primary"
                variant="contained"
                style={{ margin: "20px auto" }}
                onClick={() => setRenderLines(renderLines + 1000)}
              >
                {t("Show more lines")}
              </Button>
            )}
          </>
        ) : (
          <pre className={classes.code}>{vizData}</pre>
        )}
      </>
    );
  } else {
    return (
      <Box mt={1}>
        <Typography variant="subtitle1">{t(error)}</Typography>
      </Box>
    );
  }
}

